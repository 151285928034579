import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  InputGroup,
  Input,
  Button,
  InputRightElement,
  VStack,
  Container,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useAuthentication } from "../../context/authentication";
import { FirebaseError } from "firebase/app";
import { AuthErrorCodes } from "firebase/auth";

export const Login = () => {
  const toast = useToast()
  const navigate = useNavigate();
  const { doLogin } = useAuthentication();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const onSubmit = async () => {
    const { email, password } = formValues;

    try {
      setIsSubmitting(true);

      await doLogin(email, password);

      navigate("/");
    } catch (e) {
      if (e instanceof FirebaseError) {
        let message: String = ""

        switch (e.code) {
          case AuthErrorCodes.INVALID_PASSWORD:
            message = "Wrong Password"
            break;
          case AuthErrorCodes.INVALID_EMAIL:
            message = "Invalid Email"
            break
          case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
            message = "Too many sign in attempts too fast, try again later."
            break;
          default:
            message = e.code
        }

        toast({
          title: "Error",
          description: `An error occured:\n${message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      }
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <VStack
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={"100vh"}
    >
      <Container maxW="md">
        <form>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputGroup size="md" marginBottom={"1rem"}>
              <Input
                pr="4.5rem"
                type="text"
                placeholder="Email"
                name="email"
                value={formValues.email}
                onChange={handleChange}
              />
            </InputGroup>

            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={formValues.password}
                onChange={handleChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>

            <VStack>
              <Text
                textAlign="right"
                width="full"
                marginTop="0.5rem"
                marginBottom="1rem"
                color="blue.500"
              >
                <Link to="/forgot-password">Forgot Password?</Link>
              </Text>

              <Button
                isLoading={isSubmitting}
                colorScheme="blue"
                variant="solid"
                onClick={onSubmit}
                disabled={isSubmitting}
                loadingText="Loading"
                spinnerPlacement="start"
                type="button"
                w="full"
                marginTop={"1rem"}
              >
                {isSubmitting ? "logging..." : "Sign In"}
              </Button>
            </VStack>
          </div>
        </form>
      </Container>
    </VStack>
  );
};
